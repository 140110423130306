import React from "react";
import { Button, Divider, Icon, useViewport } from "@bookingcom/bui-react";
import SortIcon from "@bookingcom/bui-assets-react/streamline/SortIcon";
import { useTranslations } from "@bookingcom/lingojs-react";
import { useTaxiLocationManager } from "./useTaxiLocationManager";
import { TaxiAutocomplete } from "../TaxiAutocomplete";
import { TEST_IDS } from "./TaxiLocationManager.constants";
import styles from "./TaxiLocationManager.module.css";

export interface TaxiLocationManagerProps extends React.Attributes {}

export const TaxiLocationManager: React.FC<TaxiLocationManagerProps> = () => {
	const { isMobile, isSwapDisabled, onClickSwap } = useTaxiLocationManager();
	const { isSmall, isMedium } = useViewport();
	const sizeText = isSmall ? "small" : isMedium ? "medium" : "large";

	const { translate: t } = useTranslations();

	const classes = [styles["taxi-location-manager"], styles[`taxi-location-manager--${sizeText}-size`]].join(" ");

	return (
		<div className={classes} data-testid={TEST_IDS.locationManager}>
			<div className={`${styles["taxi-location-manager__input"]} ${styles["taxi-location-manager__input--pickup"]}`}>
				<TaxiAutocomplete
					name="pickup"
					label={t("gt_mig_rides_web_search_form_search_pick-up-location")}
					placeholder={t("gt_mig_rides_web_search_form_search_pickup-location-placeholder")}
				/>
			</div>
			{isMobile && (
				<div className={styles[`taxi-location-manager__route-line`]} data-testid={TEST_IDS.locationManagerRoute} />
			)}
			<Button
				className={styles[`taxi-location-manager__swap-button`]}
				icon={
					<Icon
						svg={<SortIcon />}
						className={styles[`taxi-location-manager__swap-button-icon`]}
						size="medium"
						color={isSwapDisabled ? undefined : "neutral"}
					/>
				}
				variant="tertiary"
				size="large"
				disabled={isSwapDisabled}
				onClick={onClickSwap}
				attributes={{
					"data-testid": TEST_IDS.locationSwapButton,
					"aria-label": t("gt_mig_rides_web_search_form_search_swap-pickup-and-dropoff"),
				}}
			/>
			<div className={`${styles["taxi-location-manager__input"]} ${styles["taxi-location-manager__input--dropoff"]}`}>
				{isMobile && (
					<Divider
						className={styles[`taxi-location-manager__divider`]}
						attributes={{ "data-testid": TEST_IDS.locationDivider }}
					/>
				)}
				<TaxiAutocomplete
					name="dropoff"
					label={t("gt_mig_rides_web_search_form_search_drop-off-location")}
					placeholder={t("gt_mig_rides_web_search_form_search_destination-location-placeholder")}
				/>
			</div>
		</div>
	);
};
