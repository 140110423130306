import type { ActionInterface } from "../contexts/SearchFormContext/types";
import type { AutocompleteResponseInterface, AutocompleteResultInterface } from "../server/services/taxi-autocomplete";
import { SubmitFormPayload } from "./types";

export const SET_PASSENGERS = "SET_PASSENGERS";

export const SET_SELECTED_AIRPORT = "SET_SELECTED_AIRPORT";
export const SUBMIT_FORM = "SUBMIT_FORM";
export const SET_RESULTS = "SET_RESULTS";
export const CLEAR_RESULTS = "CLEAR_RESULTS";
export const SELECT_RESULT = "SELECT_RESULT";
export const SET_RETURN = "SET_RETURN";
export const SWAP_LOCATIONS = "SWAP_LOCATIONS";
export const TOGGLE_ERROR = "TOGGLE_ERROR";
export const TOGGLE_ROUTE_PLANNER = "TOGGLE_ROUTE_PLANNER";
export const SET_DATE_TIME = "SET_DATE_TIME";

export const setPassengers = (passengers: string): ActionInterface => ({
	type: SET_PASSENGERS,
	payload: passengers,
});

export const setSelectedAirport = (airportData: { establishment: string; iata: string }): ActionInterface => ({
	type: SET_SELECTED_AIRPORT,
	payload: airportData,
});

export const submitForm = (redirectUrl: string | SubmitFormPayload): ActionInterface => ({
	type: SUBMIT_FORM,
	payload: redirectUrl,
});

export const selectResult = (name: string, result?: AutocompleteResultInterface): ActionInterface => ({
	type: SELECT_RESULT,
	payload: {
		name,
		result,
	},
});

export const setResults = (name: string, response: AutocompleteResponseInterface): ActionInterface => ({
	type: SET_RESULTS,
	payload: {
		name,
		response,
	},
});

export const clearResults = (name: string): ActionInterface => ({
	type: CLEAR_RESULTS,
	payload: {
		name,
	},
});

export const setReturnJourney = (isReturn: boolean): ActionInterface => ({
	type: SET_RETURN,
	payload: {
		isReturn,
	},
});

export const swapLocations = (): ActionInterface => ({
	type: SWAP_LOCATIONS,
});

export const toggleError = (name: string, hasError: boolean): ActionInterface => ({
	type: TOGGLE_ERROR,
	payload: {
		name,
		hasError,
	},
});

export const toggleRoutePlanner = (name = ""): ActionInterface => ({
	type: TOGGLE_ROUTE_PLANNER,
	payload: {
		name,
	},
});

export const setDateTime = (name: string, date: Date): ActionInterface => ({
	type: SET_DATE_TIME,
	payload: {
		name,
		date,
	},
});
