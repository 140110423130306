import type { LocationTypeInterface, SearchFormProviderProps, SearchFormStateInterface } from "./types";
import { isDateTimeValid, isPassengersValid } from "../../utilities/search-form-provider-validators";
import { getDateByOffset, getValidDropDownDate } from "../../utilities/dates";
import { DEFAULT_STATE } from "./SearchFormProvider.constants";

export function generateSelectedLocationFromNameAndPlaceId(
	name: SearchFormProviderProps["pickUpPlaceName"] | SearchFormProviderProps["dropOffPlaceName"],
	googlePlaceId: SearchFormProviderProps["pickUpPlaceId"] | SearchFormProviderProps["dropOffPlaceId"],
	type: string,
): LocationTypeInterface {
	return {
		hasError: false,
		results: [],
		selected: {
			name: name as string,
			googlePlaceId: googlePlaceId as string,
			type,
		},
	};
}

export function setValidDates(
	initialState: Record<string, undefined> | SearchFormStateInterface,
	outboundDateTime: SearchFormProviderProps["outboundDateTime"],
	returnDateTime: SearchFormProviderProps["returnDateTime"],
	dateRangeStart: SearchFormProviderProps["dateRangeStart"],
	dateRangeEnd: SearchFormProviderProps["dateRangeEnd"],
): void {
	if (isDateTimeValid(dateRangeStart)) {
		initialState.dateRangeStart = getValidDropDownDate(new Date(dateRangeStart as string));
		initialState.outboundDateTime = getValidDropDownDate(new Date(dateRangeStart as string));
	}

	if (isDateTimeValid(dateRangeEnd)) {
		initialState.dateRangeEnd = getValidDropDownDate(new Date(dateRangeEnd as string));
	}

	const offset = 7;
	if (isDateTimeValid(outboundDateTime) && !isDateTimeValid(returnDateTime)) {
		initialState.outboundDateTime = getValidDropDownDate(new Date(outboundDateTime as string));
		initialState.returnDateTime = getValidDropDownDate(getDateByOffset(initialState.outboundDateTime, offset));
		return;
	}

	if (isDateTimeValid(outboundDateTime)) {
		initialState.outboundDateTime = getValidDropDownDate(new Date(outboundDateTime as string));
	}

	if (isDateTimeValid(returnDateTime)) {
		initialState.returnDateTime = getValidDropDownDate(new Date(returnDateTime as string));
	}
}

export function createInitialState(props: SearchFormProviderProps): SearchFormStateInterface {
	const initialState: SearchFormStateInterface = {
		...DEFAULT_STATE,
	};

	if (isPassengersValid(props.passengers)) {
		initialState.passengers = props.passengers as string;
	}

	if (props.pickUpPlaceId && props.pickUpPlaceName) {
		initialState.pickup = generateSelectedLocationFromNameAndPlaceId(
			props.pickUpPlaceName,
			props.pickUpPlaceId,
			props.pickUpType,
		);
	}

	setValidDates(initialState, props.outboundDateTime, props.returnDateTime, props.dateRangeStart, props.dateRangeEnd);

	if (props.dropOffPlaceId && props.dropOffPlaceName) {
		initialState.dropoff = generateSelectedLocationFromNameAndPlaceId(
			props.dropOffPlaceName,
			props.dropOffPlaceId,
			props.dropOffType,
		);
	}

	if (props.isReturn !== undefined) {
		initialState.isReturn = props.isReturn;
	}

	if (props.usePubSubEvent !== undefined) {
		initialState.usePubSubEvent = props.usePubSubEvent;
	}

	if (props.preferLocationApi !== undefined) {
		initialState.preferLocationApi = props.preferLocationApi;
	}

	if (props.hidePassengerField !== undefined) {
		initialState.hidePassengerField = props.hidePassengerField;
	}

	if (props.enableClickToReturn !== undefined) {
		initialState.enableClickToReturn = props.enableClickToReturn;
	}

	if (props.enableLandingPageVariant !== undefined) {
		initialState.enableLandingPageVariant = props.enableLandingPageVariant;
	}

	if (props.discount !== undefined) {
		initialState.discount = props.discount;
	}

	if (props.lang !== undefined) {
		initialState.lang = props.lang;
	}

	if (props.airports !== undefined) {
		initialState.airports = props.airports;
	}

	if (props.maxPassengers !== undefined) {
		initialState.maxPassengers = props.maxPassengers;
	}

	if (props.preferredIata !== undefined) {
		initialState.preferredIata = props.preferredIata;
	}

	if (props.autocompleteBaseUrl !== undefined) {
		initialState.autocompleteBaseUrl = props.autocompleteBaseUrl;
	}

	return initialState;
}
