import { getDefaultDate } from "../../components/TaxiDateTimePicker/dateTimeUtil";
import { DEFAULT_LANG, DEFAULT_PASSENGERS } from "../../constants/defaults";
import type { SearchFormStateInterface } from "./types";

export const DEFAULT_STATE: SearchFormStateInterface = {
	dateRangeEnd: undefined,
	dateRangeStart: undefined,
	discount: undefined,
	dropoff: {
		results: [],
		hasError: false,
	},
	enableClickToReturn: false,
	enableLandingPageVariant: false,
	hasReturnDateError: false,
	hidePassengerField: false,
	isReturn: false,
	lang: DEFAULT_LANG,
	maxPassengers: undefined,
	outboundDateTime: getDefaultDate(7),
	passengers: DEFAULT_PASSENGERS,
	pickup: {
		results: [],
		hasError: false,
	},
	preferredIata: undefined,
	returnDateTime: getDefaultDate(8),
	showRoutePlanner: false,
};
