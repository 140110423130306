export const waitForCaplaHydrationReady = async (): Promise<boolean> => {
	// eslint-disable-next-line @typescript-eslint/dot-notation
	if (window["bookingCaplaMfsHydrationReady"]) {
		return true;
	}

	return new Promise((resolve) => {
		// Capla fires the below event
		window.addEventListener("booking-capla-mfs-hydration-ready", () => resolve(true), { once: true });
	});
};
