import React, { useEffect } from "react";
import { getOrchestrator } from "@bookingcom/web-page-orchestrator";
import { useTranslations } from "@bookingcom/lingojs-react";
import { InputRadio, InputRadioGroup, HiddenVisually } from "@bookingcom/bui-react";
import { useSearchForm } from "../../hooks/useSearchForm";
import { setReturnJourney } from "../../actions/searchForm";
import styles from "./ReturnToggle.module.css";
import { TEST_IDS } from "./ReturnToggle.constants";

enum Options {
	OneWay = "oneway",
	Return = "return",
}

interface ReturnToggleProps {
	isLandingPage: boolean;
}

export const ReturnToggle: React.FC<ReturnToggleProps> = ({ isLandingPage }) => {
	const { state, dispatch } = useSearchForm();

	const orchestrator = getOrchestrator();

	useEffect(() => {
		orchestrator?.on("TRIGGER_SEARCH_FORM_RETURN", async (): Promise<void> => {
			dispatch(setReturnJourney(true));
		});
	}, [orchestrator]);

	const { translate: t } = useTranslations();

	return (
		<fieldset
			className={`${styles["mfe-search-form-return-toggle"]} ${isLandingPage ? styles["inverted-colours"] : ""}`}
		>
			<HiddenVisually>
				<legend>{t("gt_mig_rides_web_search_form_search_journey-type")}</legend>
			</HiddenVisually>
			<InputRadioGroup
				name="returnJourney"
				value={state.isReturn ? Options.Return : Options.OneWay}
				onChange={({ value }) => {
					if (value === Options.Return) {
						orchestrator?.runCommand("TRACK_GOAL", {
							experiment: "returnsV3",
							goal: "addingReturnUsingSearchToggle",
						});
					}

					dispatch(setReturnJourney(value === Options.Return));
				}}
				className={styles["mfe-search-form-radio-group"]}
			>
				<InputRadio
					label={t("gt_mig_rides_web_search_form_search_one-way")}
					value={Options.OneWay}
					attributes={{
						"data-testid": TEST_IDS.radioOneWay,
					}}
					inputAttributes={{
						"aria-label": t("gt_mig_rides_web_search_form_search_one-way"),
						"data-testid": TEST_IDS.radioOneWayInput,
					}}
				/>
				<InputRadio
					label={t("gt_mig_rides_web_search_form_search_return")}
					value={Options.Return}
					attributes={{
						"data-testid": TEST_IDS.radioReturn,
					}}
					inputAttributes={{
						"aria-label": t("gt_mig_rides_web_search_form_search_return"),
						"data-testid": TEST_IDS.radioReturnInput,
					}}
				/>
			</InputRadioGroup>
		</fieldset>
	);
};
