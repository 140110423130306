import { renderComponents, domContentLoaded } from "@btu-tools/master-builder-client";
import { SearchFormApp } from "../../components/SearchFormApp";
import { registerLingoTags } from "../../constants/registerLingoTags";
import { waitForCaplaHydrationReady } from "../../utilities/wait-for-capla-hydration-ready";

// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle
declare let __webpack_public_path__: string;
// eslint-disable-next-line prefer-destructuring, @typescript-eslint/no-unused-vars, prefer-const
__webpack_public_path__ = document.currentScript.getAttribute("src").match(/(.*\/)js\/[^/]*\.js$/)[1];

// eslint-disable-next-line unicorn/prefer-top-level-await
(async () => {
	if (window?.bookingIsCaplaLandingPage === true) {
		await waitForCaplaHydrationReady();
	} else {
		await domContentLoaded();
	}

	renderComponents({ SearchFormApp: { component: SearchFormApp } });
})();

registerLingoTags();

/* istanbul ignore next */
if (module.hot) {
	module.hot.accept("../../components/SearchFormApp", () => {
		renderComponents({
			SearchFormApp: {
				component: require("../../components/SearchFormApp").SearchFormApp,
			},
		});
	});
}
