import React, { useEffect, useRef } from "react";
import { Text, InputSelect, Stack, useViewport } from "@bookingcom/bui-react";
import classnames from "classnames";
import { useTranslations } from "@bookingcom/lingojs-react";
import { generateHours, generateMinutes } from "./dateTimeUtil";
import { TEST_IDS } from "./TaxiDateTimePicker.constants";
import styles from "./TaxiDateTimePicker.module.css";
import { CalendarWrapper } from "../Calendar";

export type TaxiDateTimePickerProps = {
	classNames?: string[];
	dateRangeEnd?: Date;
	dateRangeStart?: Date;
	hours: string;
	isVisible: boolean;
	minutes: string;
	name: "oneway" | "return";
	onBlur: () => void;
	onDateChange: ({ changedDate }: { changedDate: Date }) => void;
	onTimeChange: ({ name, value }: { name: string; value: string }) => void;
	renderInModal?: boolean;
	selectedDate: Date;
};

export const TaxiDateTimePicker: React.FC<TaxiDateTimePickerProps> = ({
	classNames,
	dateRangeEnd,
	dateRangeStart,
	hours,
	isVisible,
	minutes,
	name,
	onBlur,
	onDateChange,
	onTimeChange,
	renderInModal,
	selectedDate,
}) => {
	const ref = useRef<HTMLDivElement>(null);
	const { translate: t } = useTranslations();

	const { isSmall, isMedium } = useViewport();

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent): void => {
			if (isSmall || ref.current?.contains(event.target as HTMLElement)) {
				return;
			}

			onBlur();
		};

		document.addEventListener("mousedown", handleClickOutside);

		return (): void => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [isSmall, onBlur]);

	if (!isVisible) {
		return null;
	}

	let defaultClassNames = [
		styles["taxi-date-time-picker"],
		styles[`taxi-date-time-picker--${isSmall ? "small" : isMedium ? "medium" : "large"}-size`],
	];

	if (classNames && classNames.length > 0) {
		// TODO: this seems shonky
		defaultClassNames = [...defaultClassNames, ...classNames.map((className) => styles[className] ?? className)];
	}

	return (
		<div
			className={classnames(defaultClassNames.join(" "), {
				[styles["taxi-date-time-picker--desktop"]]: !isSmall,
				[styles["taxi-date-time-picker__time-picker-fixed"]]: renderInModal && !isSmall,
			})}
			data-testid={TEST_IDS.taxiDateTimePicker}
			ref={ref}
		>
			<div className={styles["taxi-date-time-picker__calendar"]}>
				<CalendarWrapper
					dataTestId={TEST_IDS.taxiDateTimePickerCalendar}
					dateRangeEnd={dateRangeEnd}
					dateRangeStart={dateRangeStart}
					onDateChange={onDateChange}
					selectedDate={selectedDate}
				/>
			</div>
			<Stack
				direction="row"
				className={styles["taxi-date-time-picker__time-picker"]}
				alignItems="center"
				attributes={{
					"data-testid": TEST_IDS.taxiDateTimePickerTimePicker,
				}}
				grow
			>
				<Stack.Item alignSelf="center" className={styles["taxi-date-time-picker__time-picker-pickup-time"]}>
					<Text variant="body_2">{t("gt_mig_rides_web_search_form_calendar_pick-up-time")}</Text>
				</Stack.Item>
				<Stack.Item grow alignSelf="end">
					<Stack direction="row" justifyContent="end">
						<Stack.Item>
							<InputSelect
								name={`hours-${name}`}
								options={generateHours()}
								inputAttributes={{
									"data-testid": `${TEST_IDS.taxiDateTimePickerHoursPrefix}-${name}`,
								}}
								value={hours}
								onChange={onTimeChange}
							/>
						</Stack.Item>
						<Stack.Item>
							<InputSelect
								name={`minutes-${name}`}
								options={generateMinutes()}
								inputAttributes={{
									"data-testid": `${TEST_IDS.taxiDateTimePickerMinutesPrefix}-${name}`,
								}}
								value={minutes}
								onChange={onTimeChange}
							/>
						</Stack.Item>
					</Stack>
				</Stack.Item>
			</Stack>
		</div>
	);
};
